import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataStorageService } from 'src/app/shared/services/data-storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  isLoading = false;
  error = '';
  message: '';
  errors = [];
  token: string | null;
  check_token: boolean;
  form = this.fb.group({
    password: ['', [Validators.required]],
    password_confirmation: ['', [Validators.required]],
  });
  constructor(
    private fb: FormBuilder,
    private dataStorage: DataStorageService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.dataStorage
        .authPostNoAuth('user/forgot-password/check-otp', {
          token: params.get('token'),
        })
        .subscribe(
          (resData) => {
            if (resData.status === 200) {
              this.token = params.get('token');
            } else {
              this.errors.push('The link is invalid or has expired');
            }
          },
          (error) => {
            this.errors.push('The link is invalid or has expired');
          }
        );
    });
  }
  reset() {
    this.errors = [];
    this.message = '';
    this.isLoading = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    // Vaidate token
    const data = {
      token: this.token,
      password: this.form.value['password'],
      confirm_password: this.form.value['password_confirmation'],
    };

    return this.dataStorage
      .authPostNoAuth('user/forgot-password/reset', data)
      .subscribe(
        (resData) => {
          this.isLoading = false;
          if (resData.status === 200) {
            this.message = resData.message;
            setTimeout(() => {
              this.router.navigate(['login']);
            }, 5000)
          }
        },
        (error) => {
          this.isLoading = false;
          if (error.error.errors) {
            if (error.error.errors.password) {
              this.errors.push(error.error.errors.password);
            }
            if (error.error.errors.confirm_password) {
              this.errors.push(error.error.errors.confirm_password);
            }
            if (error.error.errors.token) {
              this.errors.push(error.error.errors.token);
            }
          } else {
            this.errors.push(
              this.errors.push(error.error.message)
            );
          }
        }
      );
  }
  checkCode(data: any): boolean {
    this.dataStorage
      .authPostNoAuth('user/forgot-password/check-otp', {
        token: data,
      })
      .subscribe((resData) => {
        if (resData.status === 200) {
          this.check_token = true;
        } else {
          this.check_token = false;
        }
      });
    return this.check_token;
  }
}
